import { useDispatch, useSelector } from 'react-redux';
import {
  StoreState,
  BasketDataState,
  basketDataSlice,
  legacyVersionBridge,
} from '../../../state';
import { useEffect } from 'react';

export interface BasketDisplayEffect {
  showBasket: () => void;
  hideBasket: () => void;
  toggleBasket: (show: boolean) => void;
  basketOpen: boolean;
}

export const useBasketDisplayEffect = (): BasketDisplayEffect => {
  const dispatch = useDispatch();

  const { basketDataState } = useSelector((state: StoreState) => ({
    basketDataState: state.data.basket,
  }));

  const updateBasketOpen = (open: boolean) => {
    const updatedBasketDataState: BasketDataState = Object.assign({}, {
      ...basketDataState,
      basketOpen: open,
    } as BasketDataState);

    dispatch(basketDataSlice.actions.update(updatedBasketDataState));
    legacyVersionBridge.cart.hideBasket(dispatch, open);

    if (open) {
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
    }
  };

  useEffect(() => {
    updateBasketOpen(false);
    document.body.classList.remove('sidebar-open');
  }, []);

  return {
    showBasket: () => updateBasketOpen(true),
    hideBasket: () => updateBasketOpen(false),
    toggleBasket: () => updateBasketOpen(!basketDataState.basketOpen),
    basketOpen: basketDataState.basketOpen,
  };
};
