import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { MODIFY_CART_TRIAL_GQL } from '../../../../graphql';
import { Cart } from '@hiwaldo/sdk/interfaces';

export interface ModifyCartTrialInput {
  cartId: number;
  trialId: number;
  planId: number;
  frequencyId: number;
  quantity: number;
  leftEyeProductVariantId: number | undefined;
  rightEyeProductVariantId: number | undefined;
}

export interface ModifyCartTrialEffectArgs {
  onComplete?: (cart: Cart) => void;
  onError?: (error: string) => void;
}

export interface ModifyCartTrialEffectReturn {
  enact: (id: number, data: ModifyCartTrialInput) => void;
  clear: () => void;
  data: Cart | undefined;
  loading: boolean;
  success: boolean | undefined;
  error: string | undefined;
}

export const useModifyCartTrialEffect = (
  args?: ModifyCartTrialEffectArgs
): ModifyCartTrialEffectReturn => {
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [data, setData] = useState<Cart | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const [doModifyCartTrial, { loading }] = useMutation(MODIFY_CART_TRIAL_GQL, {
    onCompleted: (data: { modifyCartTrial: Cart }) => {
      if (data.modifyCartTrial) {
        setData(data.modifyCartTrial);
        setSuccess(true);

        if (args?.onComplete) {
          args.onComplete(data.modifyCartTrial);
        }
      }
    },
    onError: (errors) => {
      setSuccess(false);
      setErrorMessage(errors.message);

      if (args?.onError) {
        args.onError(errors.message);
      }
    },
  });

  const modifyCartTrial = (id: number, data: ModifyCartTrialInput): void => {
    doModifyCartTrial({ variables: { id, data } });
  };

  const clear = (): void => {
    setSuccess(undefined);
    setData(undefined);
    setErrorMessage(undefined);
  };

  return {
    enact: modifyCartTrial,
    clear,
    data,
    loading,
    success,
    error: errorMessage,
  };
};
