import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_CART_PRODUCT_VARIANT_GQL } from '../../../../graphql';
import { Cart } from '@hiwaldo/sdk/interfaces';

export interface CreateCartProductVariantInput {
  cartId: number;
  productVariantId: number;
  quantity: number;
}

export interface CreateCartProductVariantEffectArgs {
  onComplete?: (cart: Cart) => void;
  onError?: (error: string) => void;
}

export interface CreateCartProductVariantEffectReturn {
  enact: (data: CreateCartProductVariantInput) => void;
  clear: () => void;
  data: Cart | undefined;
  loading: boolean;
  success: boolean | undefined;
  error: string | undefined;
}

export const useCreateCartProductVariantEffect = (
  args?: CreateCartProductVariantEffectArgs
): CreateCartProductVariantEffectReturn => {
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [data, setData] = useState<Cart | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const [doCreateCartProductVariant, { loading }] = useMutation(
    CREATE_CART_PRODUCT_VARIANT_GQL,
    {
      onCompleted: (data: { createCartProductVariant: Cart }) => {
        if (data.createCartProductVariant) {
          setData(data.createCartProductVariant);
          setSuccess(true);

          if (args?.onComplete) {
            args.onComplete(data.createCartProductVariant);
          }
        }
      },
      onError: (errors) => {
        setSuccess(false);
        setErrorMessage(errors.message);

        if (args?.onError) {
          args.onError(errors.message);
        }
      },
    }
  );

  const createCartProductVariant = (
    data: CreateCartProductVariantInput
  ): void => {
    doCreateCartProductVariant({ variables: { data } });
  };

  const clear = (): void => {
    setSuccess(undefined);
    setData(undefined);
    setErrorMessage(undefined);
  };

  return {
    enact: createCartProductVariant,
    clear,
    data,
    loading,
    success,
    error: errorMessage,
  };
};
