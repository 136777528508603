import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { currentRegion, formatCurrency } from '../../../helpers';
import { ModifyCartTrialInput } from '../../../server';
import { CatalogTrial } from '@hiwaldo/sdk/interfaces';
import { useStoreCatalogEffect } from '../../common';
import {
  useBasketTrialUpdateEffect,
  useBasketTrialRemoveEffect,
  StoreState,
} from '../../../state';
import { CheckoutOrderSummaryTrial } from '../../../components';

export interface BasketTrialEffect {
  loading: boolean;
  trials: CheckoutOrderSummaryTrial[] | undefined;
  errorMessage: string | undefined;
  setErrorMessage: (message: string | undefined) => void;
  updateTrial: (id: number, data: ModifyCartTrialInput) => void;
}

export const useBasketTrialEffect = (): BasketTrialEffect => {
  const region = currentRegion();

  const { basketDataState } = useSelector((state: StoreState) => ({
    basketDataState: state.data.basket,
  }));

  const [trialItems, setTrialItems] = useState<
    CheckoutOrderSummaryTrial[] | undefined
  >(undefined);

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const basketTrialUpdate = useBasketTrialUpdateEffect();
  const basketTrialRemove = useBasketTrialRemoveEffect();

  const { catalog } = useStoreCatalogEffect();

  const updateTrial = (id: number, data: ModifyCartTrialInput) => {
    setLoading(true);
    basketTrialUpdate.enact(id, data);
  };

  const removeTrial = (id: number) => {
    setLoading(true);
    basketTrialRemove.enact(id);
  };

  useEffect(() => {
    const {
      cart,
      leftEye,
      rightEye,
      includesBundle,
      includesBothEyes,
      includesDuplicateEyes,
    } = basketDataState;

    const trials = cart?.trials?.length
      ? cart.trials.map((trial): CheckoutOrderSummaryTrial => {
          let trialItem: CatalogTrial | undefined;
          let icon = '';

          switch (+trial.trial.id) {
            case 1:
              trialItem = catalog?.trialHydraBoost;
              icon = '/images/products/original-lens/sleeves-Lens2.png';
              break;
            case 2:
              trialItem = catalog?.trialHydraBoostBundle;
              icon = '/images/home/products/eyecare_starter_kit.png';
              break;
            case 3:
              trialItem = catalog?.trialVitaminBoost;
              icon = '/images/home/products/hbp-lenses.png';
              break;
            case 4:
              trialItem = catalog?.trialVitaminBoostBundle;
              icon = '/images/funnel/hbp_eyecare_starter_kit.png';
              break;
          }

          const trialLocale = trialItem?.locales?.find(
            (locale) => locale.region === region
          );

          return {
            id: trial.id,
            title: trialLocale?.title || trial.trial.title,
            linePrice: formatCurrency({ amount: trial.linePrice }),
            quantity: trial.quantity || 1,
            onRemoveHandler: () => removeTrial(trial.id),
            includesBundle,
            includesBothEyes,
            includesDuplicateEyes,
            leftEye,
            rightEye,
            icon,
          };
        })
      : undefined;

    if (JSON.stringify(trials) !== JSON.stringify(trialItems)) {
      setTrialItems(trials);
    }

    if (!basketTrialUpdate.loading) {
      if (basketTrialUpdate.success) {
        basketTrialUpdate.clear();
        setLoading(false);
      }

      if (basketTrialUpdate.error) {
        basketTrialUpdate.clear();
        setErrorMessage('Error updating trial!');
        setLoading(false);
      }
    }

    if (!basketTrialRemove.loading) {
      if (basketTrialRemove.success) {
        basketTrialRemove.clear();
        setLoading(false);
      }

      if (basketTrialRemove.error) {
        basketTrialRemove.clear();
        setErrorMessage('Error removing trial!');
        setLoading(false);
      }
    }
  }, [trialItems, basketDataState, basketTrialUpdate, basketTrialRemove]);

  return {
    loading,
    trials: trialItems,
    errorMessage,
    setErrorMessage,
    updateTrial,
  };
};
