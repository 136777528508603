import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cartParser, legacyVersionBridge, StoreState } from '../../../';
import { Cart } from '@hiwaldo/sdk/interfaces';
import { basketDataSlice } from '../../../redux';
import { useRemoveCartTrialEffect } from '../../../../server';

export interface BasketTrialRemoveDataEffectReturn {
  enact: (id: number) => void;
  clear: () => void;
  loading: boolean;
  success: boolean | undefined;
  error?: string;
}

export interface BasketTrialRemoveDataEffectProps {
  onComplete?: (cart: Cart) => void;
  onError?: (error: string) => void;
}

export const useBasketTrialRemoveEffect = (
  props?: BasketTrialRemoveDataEffectProps
): BasketTrialRemoveDataEffectReturn => {
  const dispatch = useDispatch();

  const { basketDataState } = useSelector((state: StoreState) => ({
    basketDataState: state.data.basket,
  }));

  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const removeCartTrial = useRemoveCartTrialEffect({
    onComplete: (cart: Cart) => {
      cartParser.fromCartUpdate(basketDataState, cart).then((updatedState) => {
        removeCartTrial.clear();
        legacyVersionBridge.cart.update(dispatch, cart);
        dispatch(basketDataSlice.actions.update(updatedState));
        setSuccess(true);
        setLoading(false);

        if (props?.onComplete) {
          props.onComplete(cart);
        }
      });
    },
    onError: (error: string) => {
      removeCartTrial.clear();
      setErrorMessage(error);
      setSuccess(false);
      setLoading(false);

      if (props?.onError) {
        props.onError(error);
      }
    },
  });

  const basketTrialRemove = (id: number) => {
    setLoading(true);
    removeCartTrial.enact(id);
  };

  const clear = () => {
    setErrorMessage(undefined);
    setSuccess(undefined);
    setLoading(false);
  };

  return {
    enact: basketTrialRemove,
    clear,
    loading,
    success,
    error: errorMessage,
  };
};
