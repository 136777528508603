import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_CART_LENS_PRODUCT_VARIANTS_GQL } from '../../../../graphql';
import { Cart } from '@hiwaldo/sdk/interfaces';

export interface CreateCartLensProductVariantsInput {
  leftEye?: {
    cartId: number;
    productVariantId: number;
    quantity: number;
  };
  rightEye?: {
    cartId: number;
    productVariantId: number;
    quantity: number;
  };
  bothEyes?: {
    cartId: number;
    productVariantId: number;
    quantity: number;
  };
}

export interface CreateCartLensProductVariantsEffectArgs {
  onComplete?: (cart: Cart) => void;
  onError?: (error: string) => void;
}

export interface CreateCartLensProductVariantsEffectReturn {
  enact: (data: CreateCartLensProductVariantsInput) => void;
  clear: () => void;
  data: Cart | undefined;
  loading: boolean;
  success: boolean | undefined;
  error: string | undefined;
}

export const useCreateCartLensProductVariantsEffect = (
  args?: CreateCartLensProductVariantsEffectArgs
): CreateCartLensProductVariantsEffectReturn => {
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [data, setData] = useState<Cart | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const [doCreateCartLensProductVariants, { loading }] = useMutation(
    CREATE_CART_LENS_PRODUCT_VARIANTS_GQL,
    {
      onCompleted: (data: { createCartLensProductVariants: Cart }) => {
        if (data.createCartLensProductVariants) {
          setData(data.createCartLensProductVariants);
          setSuccess(true);

          if (args?.onComplete) {
            args.onComplete(data.createCartLensProductVariants);
          }
        }
      },
      onError: (errors) => {
        setSuccess(false);
        setErrorMessage(errors.message);

        if (args?.onError) {
          args.onError(errors.message);
        }
      },
    }
  );

  const createCartLensProductVariants = (
    data: CreateCartLensProductVariantsInput
  ): void => {
    doCreateCartLensProductVariants({ variables: { data } });
  };

  const clear = (): void => {
    setSuccess(undefined);
    setData(undefined);
    setErrorMessage(undefined);
  };

  return {
    enact: createCartLensProductVariants,
    clear,
    data,
    loading,
    success,
    error: errorMessage,
  };
};
