import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../../helpers';
import { useStoreCatalogEffect } from '../../common';
import { CartProductVariant } from '@hiwaldo/sdk/interfaces';
import { CheckoutOrderSummaryProductVariant } from '../../../components';
import {
  CreateCartLensProductVariantsInput,
  ModifyCartProductVariantInput,
} from '../../../server';
import {
  useBasketProductVariantAddEffect,
  useBasketProductVariantUpdateEffect,
  useBasketProductVariantRemoveEffect,
  useBasketProductVariantLensesEffect,
  StoreState,
} from '../../../state';

export interface BasketProductVariantEffect {
  loading: boolean;
  productVariants: CheckoutOrderSummaryProductVariant[] | undefined;
  errorMessage: string | undefined;
  setErrorMessage: (message: string | undefined) => void;
  addProductVariant: (data: ModifyCartProductVariantInput) => void;
  lensProductVariants: (data: CreateCartLensProductVariantsInput) => void;
  updateProductVariant: (
    id: number,
    data: ModifyCartProductVariantInput
  ) => void;
  removeProductVariant: (id: number) => void;
}

export const useBasketProductVariantEffect = (): BasketProductVariantEffect => {
  const { basketDataState } = useSelector((state: StoreState) => ({
    basketDataState: state.data.basket,
  }));

  const [productVariantItems, setProductVariantItems] = useState<
    CheckoutOrderSummaryProductVariant[] | undefined
  >(undefined);

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const { getRegionalProductVariant, appVariantIcon } = useStoreCatalogEffect();

  const basketProductVariantAdd = useBasketProductVariantAddEffect();

  const basketProductVariantLenses = useBasketProductVariantLensesEffect();
  const basketProductVariantUpdate = useBasketProductVariantUpdateEffect();
  const basketProductVariantRemove = useBasketProductVariantRemoveEffect();

  const addProductVariant = (data: ModifyCartProductVariantInput) => {
    setLoading(true);
    basketProductVariantAdd.enact(data);
  };

  const lensProductVariants = (data: CreateCartLensProductVariantsInput) => {
    setLoading(true);
    basketProductVariantLenses.enact(data);
  };

  const updateProductVariant = (
    id: number,
    data: ModifyCartProductVariantInput
  ) => {
    setLoading(true);
    basketProductVariantUpdate.enact(id, data);
  };

  const removeProductVariant = (id: number) => {
    setLoading(true);
    basketProductVariantRemove.enact(id);
  };

  const updateProductVariantQuantity = (
    increment: boolean,
    productVariant: CartProductVariant
  ) => {
    if (productVariant.productVariant.handle.match(/lenses/)) {
      const lensVariants = basketDataState.cart?.productVariants.length
        ? basketDataState.cart.productVariants.filter((variant) =>
            variant.productVariant.handle.match(/lenses/)
          )
        : [];

      if (lensVariants.length) {
        const duplicateEyes = basketDataState.includesDuplicateEyes;
        let updatedQuantity = lensVariants[0].quantity;

        switch (lensVariants[0].quantity) {
          case 6:
            if (duplicateEyes) {
              updatedQuantity = increment ? 12 : 3;
            } else {
              updatedQuantity = increment ? 6 : 3;
            }
            break;
          case 3:
            if (duplicateEyes) {
              updatedQuantity = increment ? 6 : 2;
            } else {
              updatedQuantity = increment ? 6 : 1;
            }
            break;
          case 2:
            updatedQuantity = increment ? 6 : 0;
            break;
          case 1:
            updatedQuantity = increment ? 3 : 0;
        }

        lensProductVariants({
          bothEyes: duplicateEyes
            ? {
                cartId: basketDataState.cart?.id || 0,
                productVariantId: lensVariants[0].productVariantId,
                quantity: updatedQuantity,
              }
            : undefined,
          leftEye:
            !duplicateEyes && basketDataState.leftEye
              ? {
                  cartId: basketDataState.cart?.id || 0,
                  productVariantId: basketDataState.leftEye.id,
                  quantity: updatedQuantity,
                }
              : undefined,
          rightEye:
            !duplicateEyes && basketDataState.rightEye
              ? {
                  cartId: basketDataState.cart?.id || 0,
                  productVariantId: basketDataState.rightEye.id,
                  quantity: updatedQuantity,
                }
              : undefined,
        });
      }
    } else {
      updateProductVariant(productVariant.id, {
        cartId: basketDataState.cart?.id || 0,
        productVariantId: productVariant.productVariantId,
        quantity: increment
          ? productVariant.quantity + 1
          : productVariant.quantity - 1,
      });
    }
  };

  useEffect(() => {
    const { cart } = basketDataState;

    let eyeMatched = false;

    const productVariants = cart?.productVariants?.length
      ? cart.productVariants.map(
          (productVariant): CheckoutOrderSummaryProductVariant => {
            const localeVariant = getRegionalProductVariant(
              productVariant.productVariant.id
            );

            let icon = '';
            let lens = false;
            let drops = false;
            let blg = false;
            let eye: 'Left eye' | 'Right eye' | 'Both eyes' | undefined;

            if (localeVariant?.variant?.handle) {
              if (localeVariant.variant.handle.match(/lenses/)) {
                lens = true;

                if (
                  basketDataState.includesDuplicateEyes &&
                  (+(basketDataState.leftEye?.id || 0) ===
                    +localeVariant.variant.id ||
                    +(basketDataState.rightEye?.id || 0) ===
                      +localeVariant.variant.id)
                ) {
                  eye = 'Both eyes';
                } else if (
                  +(basketDataState.rightEye?.id || 0) ===
                    +localeVariant.variant.id &&
                  !eyeMatched
                ) {
                  eyeMatched = true;
                  eye = 'Right eye';
                } else {
                  eye = 'Left eye';
                }
              }

              if (localeVariant.variant.handle.match(/drops/)) {
                drops = true;
              }

              if (
                localeVariant.variant.handle.match(/knox|murphy|stacia|nikka/)
              ) {
                blg = true;
              }

              icon = appVariantIcon(localeVariant.variant.handle) || '';
            }

            return {
              id: productVariant.id,
              title: localeVariant?.variantLocale.title || '',
              quantity: productVariant.quantity,
              itemPrice: formatCurrency({ amount: productVariant.itemPrice }),
              linePrice: formatCurrency({ amount: productVariant.linePrice }),
              details: productVariant.productVariant.details!,
              lens,
              drops,
              blg,
              eye,
              onIncrementHandler: () =>
                updateProductVariantQuantity(true, productVariant),
              onDecrementHandler: () =>
                updateProductVariantQuantity(false, productVariant),
              onRemoveHandler: () => removeProductVariant(productVariant.id),
              icon,
            };
          }
        )
      : undefined;

    if (
      JSON.stringify(productVariants) !== JSON.stringify(productVariantItems)
    ) {
      setProductVariantItems(productVariants);
    }

    if (!basketProductVariantAdd.loading) {
      if (basketProductVariantAdd.success) {
        basketProductVariantAdd.clear();
        setLoading(false);
      }

      if (basketProductVariantAdd.error) {
        basketProductVariantAdd.clear();
        setErrorMessage('Error adding product variant!');
        setLoading(false);
      }
    }

    if (!basketProductVariantLenses.loading) {
      if (basketProductVariantLenses.success) {
        basketProductVariantLenses.clear();
        setLoading(false);
      }

      if (basketProductVariantLenses.error) {
        basketProductVariantLenses.clear();
        setErrorMessage('Error updating lens product variants!');
        setLoading(false);
      }
    }

    if (!basketProductVariantUpdate.loading) {
      if (basketProductVariantUpdate.success) {
        basketProductVariantUpdate.clear();
        setLoading(false);
      }

      if (basketProductVariantUpdate.error) {
        basketProductVariantUpdate.clear();
        setErrorMessage('Error updating product variant!');
        setLoading(false);
      }
    }

    if (!basketProductVariantRemove.loading) {
      if (basketProductVariantRemove.success) {
        basketProductVariantRemove.clear();
        setLoading(false);
      }

      if (basketProductVariantRemove.error) {
        basketProductVariantRemove.clear();
        setErrorMessage('Error removing product variant!');
        setLoading(false);
      }
    }
  }, [
    productVariantItems,
    basketDataState,
    basketProductVariantAdd,
    basketProductVariantLenses,
    basketProductVariantUpdate,
    basketProductVariantRemove,
  ]);

  return {
    loading,
    productVariants: productVariantItems,
    errorMessage,
    setErrorMessage,
    addProductVariant,
    lensProductVariants,
    updateProductVariant,
    removeProductVariant,
  };
};
