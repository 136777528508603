import { useSelector } from 'react-redux';
import { BasketDataState, StoreState } from '../../../state';
import { formatCurrency } from '../../../helpers';
import { useEffect, useState } from 'react';
import { CheckoutOrderSummaryTotals } from '../../../components';

export interface BasketTotalEffect {
  basketDataState: BasketDataState;
  totals: CheckoutOrderSummaryTotals;
}

export const useBasketTotalEffect = (): BasketTotalEffect => {
  const { basketDataState } = useSelector((state: StoreState) => ({
    basketDataState: state.data.basket,
  }));

  const [totals, setTotals] = useState<CheckoutOrderSummaryTotals>({
    subtotal: '',
    shipping: '',
    untaxed: '',
    discount: '',
    credit: '',
    tax: '',
    total: '',
  });

  useEffect(() => {
    const { cart } = basketDataState;

    const subtotal = formatCurrency({ amount: cart?.subtotalPrice || 0 });
    const shipping = formatCurrency({
      amount: cart?.shippingOption?.shippingPrice || 0,
    });

    const untaxedCost = (cart?.grandTotalPrice || 0) - (cart?.taxPrice || 0);

    const untaxed = formatCurrency({ amount: untaxedCost });
    const discount =
      cart && (cart?.discountDeduction || 0) > 0
        ? `-${formatCurrency({
            amount: cart.discountDeduction,
          })}`
        : '';
    const credit =
      cart && (cart?.creditDeduction || 0) > 0
        ? `-${formatCurrency({ amount: cart.creditDeduction })}`
        : '';
    const tax = formatCurrency({ amount: cart?.taxPrice || 0 });
    const total = formatCurrency({ amount: cart?.grandTotalPrice || 0 });

    const updatedTotals = {
      subtotal,
      shipping,
      untaxed,
      discount,
      credit,
      tax,
      total,
    };

    if (JSON.stringify(updatedTotals) !== JSON.stringify(totals)) {
      setTotals(updatedTotals);
    }
  }, [basketDataState]);

  return {
    basketDataState,
    totals,
  };
};
