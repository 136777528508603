import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cartParser, legacyVersionBridge, StoreState } from '../../../';
import { Cart } from '@hiwaldo/sdk/interfaces';
import { basketDataSlice } from '../../../redux';
import {
  CreateCartProductVariantInput,
  useCreateCartProductVariantEffect,
} from '../../../../server';

export interface BasketProductVariantAddDataEffectReturn {
  enact: (data: CreateCartProductVariantInput) => void;
  clear: () => void;
  loading: boolean;
  success: boolean | undefined;
  error?: string;
}

export interface BasketProductVariantAddDataEffectProps {
  onComplete?: (cart: Cart) => void;
  onError?: (error: string) => void;
}

export const useBasketProductVariantAddEffect = (
  props?: BasketProductVariantAddDataEffectProps
): BasketProductVariantAddDataEffectReturn => {
  const dispatch = useDispatch();

  const { basketDataState } = useSelector((state: StoreState) => ({
    basketDataState: state.data.basket,
  }));

  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const createCartProductVariant = useCreateCartProductVariantEffect({
    onComplete: (cart: Cart) => {
      cartParser.fromCartUpdate(basketDataState, cart).then((updatedState) => {
        createCartProductVariant.clear();
        legacyVersionBridge.cart.update(dispatch, cart);
        dispatch(basketDataSlice.actions.update(updatedState));
        setSuccess(true);
        setLoading(false);

        if (props?.onComplete) {
          props.onComplete(cart);
        }
      });
    },
    onError: (error: string) => {
      createCartProductVariant.clear();
      setErrorMessage(error);
      setSuccess(false);
      setLoading(false);

      if (props?.onError) {
        props.onError(error);
      }
    },
  });

  const basketProductVariantAdd = (data: CreateCartProductVariantInput) => {
    setLoading(true);
    createCartProductVariant.enact(data);
  };

  const clear = () => {
    setErrorMessage(undefined);
    setSuccess(undefined);
    setLoading(false);
  };

  return {
    enact: basketProductVariantAdd,
    clear,
    loading,
    success,
    error: errorMessage,
  };
};
