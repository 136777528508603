import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { REMOVE_CART_TRIAL_GQL } from '../../../../graphql';
import { Cart } from '@hiwaldo/sdk/interfaces';

export interface RemoveCartTrialEffectArgs {
  onComplete?: (cart: Cart) => void;
  onError?: (error: string) => void;
}

export interface RemoveCartTrialEffectReturn {
  enact: (id: number) => void;
  clear: () => void;
  data: Cart | undefined;
  loading: boolean;
  success: boolean | undefined;
  error: string | undefined;
}

export const useRemoveCartTrialEffect = (
  args?: RemoveCartTrialEffectArgs
): RemoveCartTrialEffectReturn => {
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [data, setData] = useState<Cart | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const [doRemoveCartTrial, { loading }] = useMutation(REMOVE_CART_TRIAL_GQL, {
    onCompleted: (data: { removeCartTrial: Cart }) => {
      if (data.removeCartTrial) {
        setData(data.removeCartTrial);
        setSuccess(true);

        if (args?.onComplete) {
          args.onComplete(data.removeCartTrial);
        }
      }
    },
    onError: (errors) => {
      setSuccess(false);
      setErrorMessage(errors.message);

      if (args?.onError) {
        args.onError(errors.message);
      }
    },
  });

  const removeCartTrial = (id: number): void => {
    doRemoveCartTrial({ variables: { id } });
  };

  const clear = (): void => {
    setSuccess(undefined);
    setData(undefined);
    setErrorMessage(undefined);
  };

  return {
    enact: removeCartTrial,
    clear,
    data,
    loading,
    success,
    error: errorMessage,
  };
};
