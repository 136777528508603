import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cartParser, legacyVersionBridge, StoreState } from '../../../';
import { Cart } from '@hiwaldo/sdk/interfaces';
import { basketDataSlice } from '../../../redux';
import {
  ModifyCartTrialInput,
  useModifyCartTrialEffect,
} from '../../../../server';

export interface BasketTrialUpdateDataEffectReturn {
  enact: (id: number, data: ModifyCartTrialInput) => void;
  clear: () => void;
  loading: boolean;
  success: boolean | undefined;
  error?: string;
}

export interface BasketTrialUpdateDataEffectProps {
  onComplete?: (cart: Cart) => void;
  onError?: (error: string) => void;
}

export const useBasketTrialUpdateEffect = (
  props?: BasketTrialUpdateDataEffectProps
): BasketTrialUpdateDataEffectReturn => {
  const dispatch = useDispatch();

  const { basketDataState } = useSelector((state: StoreState) => ({
    basketDataState: state.data.basket,
  }));

  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const modifyCartTrial = useModifyCartTrialEffect({
    onComplete: (cart: Cart) => {
      cartParser.fromCartUpdate(basketDataState, cart).then((updatedState) => {
        modifyCartTrial.clear();
        legacyVersionBridge.cart.update(dispatch, cart);
        dispatch(basketDataSlice.actions.update(updatedState));
        setSuccess(true);
        setLoading(false);

        if (props?.onComplete) {
          props.onComplete(cart);
        }
      });
    },
    onError: (error: string) => {
      modifyCartTrial.clear();
      setErrorMessage(error);
      setSuccess(false);
      setLoading(false);

      if (props?.onError) {
        props.onError(error);
      }
    },
  });

  const basketTrialUpdate = (id: number, data: ModifyCartTrialInput) => {
    setLoading(true);
    modifyCartTrial.enact(id, data);
  };

  const clear = () => {
    setErrorMessage(undefined);
    setSuccess(undefined);
    setLoading(false);
  };

  return {
    enact: basketTrialUpdate,
    clear,
    loading,
    success,
    error: errorMessage,
  };
};
