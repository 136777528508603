import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatCurrency, formatDate } from '../../../helpers';
import { StoreState } from '../../../state';
import { useStoreCatalogEffect } from '../../common';
import { CheckoutSubscriptionSummaryItem } from '../../../components';

export interface BasketSubscriptionEffect {
  subscription: CheckoutSubscriptionSummaryItem | undefined;
}

export const useBasketSubscriptionEffect = (): BasketSubscriptionEffect => {
  const { basketDataState } = useSelector((state: StoreState) => ({
    basketDataState: state.data.basket,
  }));

  const [subscriptionItem, setSubscriptionItem] = useState<
    CheckoutSubscriptionSummaryItem | undefined
  >(undefined);

  const { getRegionalTrial } = useStoreCatalogEffect();

  useEffect(() => {
    const firstRenewal = new Date();
    firstRenewal.setTime(firstRenewal.getTime() + 86400000 * 12);

    const trialId =
      basketDataState.includesTrial && basketDataState.cart?.trials?.length
        ? +basketDataState.cart.trials[0].trial.id
        : undefined;

    const planId = trialId && [1, 2].includes(trialId) ? 15 : 16;
    const regionalTrial = trialId
      ? getRegionalTrial(trialId as 1 | 2 | 3 | 4, planId)
      : undefined;

    const subscription: CheckoutSubscriptionSummaryItem | undefined = trialId
      ? {
          planTitle: 'Quarterly',
          nextOrderDate: formatDate(firstRenewal),
          deliveryCadence: '3 months',
          subtotal: regionalTrial?.subtotal || '',
          shipping: formatCurrency({ amount: 0 }),
          original: regionalTrial?.original || '',
          total: regionalTrial?.total || '',
          products: regionalTrial?.products || [],
          subscriptionPlans: [
            {
              planType: 'Monthly',
              noOfLens: 60,
            },
            {
              planType: 'Quarterly',
              noOfLens: 180,
              discount_US: 7,
              discount_UK: 15,
            },
            {
              planType: 'Bi_Annual',
              noOfLens: 360,
              discount_US: 12,
              discount_UK: 20,
            },
          ],
        }
      : undefined;

    if (JSON.stringify(subscription) !== JSON.stringify(subscriptionItem)) {
      setSubscriptionItem(subscription);
    }
  }, [basketDataState]);

  return { subscription: subscriptionItem };
};
